import React from "react"

import styles from "../../styles/main.module.css"

export const ul = (ls) => (
        <ul>
            {ls.map(obj => (
            <li>
                {obj}
            </li>
            ))}
        </ul>
    )

export default (props) => <div />